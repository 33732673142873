.pdf {
  margin: 0 auto;
  height: max-content;
  width: 100% !important;
}
.react-pdf__Page > div {
  height: 2px !important;
  width: 100% !important;
  overflow: hidden;
}
.rpv-core__canvas-layer {
  border: none !important;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

:root {
  --rpv-core__page-layer-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0);
}