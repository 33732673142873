.react-datepicker-popper {
  transition: tranform 0ms;
}
.react-datepicker__tab-loop {
  position: absolute;
}
.react-datepicker__close-icon {
  margin-right: 10px;
  width: 40px;
  color: inherit;
}
.react-datepicker__close-icon::after {
  display: none;
}
.react-datepicker__input-container {
  color: inherit;
}
.react-datepicker-wrapper {
  color: inherit;
}
